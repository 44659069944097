export default {
    translation: {
        'parking-varna': 'Паркинг Grand Mall Варна',
        'pay-parking': 'Заплащане на паркинг',
        'car-number': 'Номер на автомобил',
        'please-enter-car-number': 'Моля, въведете номер на автомобил!',
        'car-number-placeholder': 'Номер на автомобил - АА0000АА',
        'recheck-after': 'Повторна проверка след',
        'parking-stay': 'Време на престой',
        'price-parking-stay': 'Цена за целия престой',
        'payment-tax': 'Платежна такса',
        'bgn': 'лв.',
        'pay': 'Плати',
        'm': 'м',
        'd': 'д',
        'h': 'ч',
        'to': 'до',
        'email-placeholder': 'E-Mail за касов бон',
        'do-you-want-receipt': 'Искам да получа електронен фискален бон и на имейл',
        'successful-payment': 'Успешно плащане',
        'paid-until': 'Платено до',
        'time-to-leave': 'Оставащо време за напускане на паркинга',
        'parking-ticket-number': 'Номер на паркинг билет',
        'expired-text': 'След изтичане на времето за напускане, ще бъдете таксувани допълнително.',
        'email-is-required': 'Моля, въведете валиден имейл адрес, за да получите касов бон!',
        'car-number-not-found': 'Няма автомобил с този номер в паркинга!',
        'payment-failed': 'Неуспешно плащане!',
        // 'payment-failed-text': 'Възникна грешка при плащането. Моля, опитайте отново!',
        'payment-failed-text': 'Моля, опитайте отново!',
        'try-again': 'Опитайте отново',
        'cyrillic-not-allowed': 'Номерът на автомобила не може да съдържа кирилица!',
        'updated-at': 'Обновено в',
        'please-choose-zone': 'Моля, изберете зона!',
        'hour': 'ч.',
        'hours': 'часа',
        'please-choose-duration': 'Моля, изберете продължителност на паркирането!',
        'zone-time-left': 'Оставащо време за паркиране',
        'extend-parking': 'Удължи паркирането',
        'check-another-number': 'Провери друг номер',
        'powered-by': 'powered by',
        'private-parking': 'Паркинг',
        'extend-parking-label': 'Може да удължите вашето време за паркиране',
        'pay-for-another-number': 'Плати за друг номер',
        'choose-duration-label': 'Изберете време за паркиране',
        'enter-car-number': 'Въведете номер на автомобил',
        'out-of-working-hours': 'Избраната от Вас зона е извън работно време!',
        'active-parking-found': 'Намерено e активно паркиране за този номер! Желаете ли да видите повече информация за него?',
        'failed-payments': 'В момента се ивзвършва ъпдейт на системата!',
        'failed-payments-2': 'Моля да използвате терминалите за плащане!',
        'check-clamp': 'Проверка за скоба',
        'check': 'Провери',
        'lifted-car': 'На автомобила Ви е поставена скоба.',
        'located-at': 'Заскобеният автомобил се намира на адрес ',
        'fee': 'Глоба',
        'clamp-paid': 'Заплатихте за освобождаване от скоба на ',
        'clamp-paid-successfully': 'Успешно заплатихте за освобождаване от скоба на ',
        'check-another-car': 'Провери друг автомобил',
        'no-clamp': 'Не е намерена скоба за този автомобил!',
        'parking-is-free': 'Избраната зона не е активна в момента.',
        'choose-zone': 'Моля, изберете зона!',
        'bg': 'Български',
        'en': 'English',
        'choose-ticket-type': 'Избор на тип билет',
        'your-tickets': 'Твоите билети',
        'total': 'Общо',
        'payment-methods': 'Метод за плащане',
        'card': 'Плащане с карта',
        'got-ages': 'Потвърди, че имаш навършени 18 години.',
        'tickets': 'Билети',
        'enter-email-info': 'Въведете Вашият E-Mail за получване на билети',
        'enter-email': 'Въведете E-Mail',
        'confirm-email': 'Потвърдете E-Mail',
        'buy': 'Купи',
        // 'general-terms-info': 'С натискане на бутоните: "Плати като гост" или "Регистрация", Вие се съгласявате с нашите ',
        'general-terms-info': 'С натискане на бутона "Купи", Вие се съгласявате с нашите ',
        'general-terms': 'Правила и условия за ползване',
        'owner-terms-info': 'Съгласен съм с ',
        'owner-terms': 'Общите условия на Организатора',
        'owner-file-info': 'Съгласен съм с ',
        'owner-file': 'Общите условия на Събитието',
        'privacy-policy-info': 'Научете как събираме, използваме и споделяме вашите данни в нашата ',
        'privacy-policy': 'Политика за поверителност',
        'provide-valid-email': 'Моля, въведете валиден имейл адрес!',
        'email-is-required': 'Моля, въведете имейл адрес!',
        'emails-must-match': 'Имейлите трябва да съвпадат!',
        'got-ages-required': 'Трябва да потвърдите, че сте навършили 18 години!',
        'general-terms-required': 'Моля, потвърдете, че сте запознати с Общите условия!',
        'owner-terms-required': 'Моля, потвърдете, че сте запознати с условията на Ораганизатора!',
        'owner-file-required': 'Моля, потвърдете, че сте запознати с условията на Събитието!',
        'view-more': 'Виж повече',
        // 'event-paid-successfully': 'Успешно заплатихте за събитието! Вашият билет ще бъде изпратен на посоченият имейл адрес!',
        'event-paid-successfully': 'Успешно заплатихте Вашата поръчка! Повече детайли може да намерите на посоченият имейл адрес!',
        'buy-another-event': 'Купи отново',
        'standart': 'Стандартен',
        'child': 'Детски',
        'student': 'Студент',
        'retired': 'Пенсионер',
        'disabled': 'Инвалиден',
        'organizer-info-tab': 'За обекта',
        'important-info': 'Важно',
        'organizer-info': 'Работно време',
        'description':'Описание',
        'clamp':'Скоба',
        'car-has-clamp': 'На Вашия автомобил е поставена скоба.',
        'car-has-clamp-2': 'Може да заплатите освобождането.',
        'adm-tax': 'Административна такса',
        'choose-date': 'Избери дата',
        'choose': 'Избери',
        'choose-seats': 'Избери чадъри', 
        'search-seat-name': 'Въведи номер на чадър', 
        'seat-number': 'Чадър', 
        'row': 'Ред', 
        'free': 'Свободен', 
        'occupied': 'Зает', 
        'add': 'Добави', 
        'add-anc': '+Артикули', 
        'event-ancillaries': 'Добави артикули', 
        'number': 'Номер',
        'loading': 'Зареждане',
        'submit-anc': 'Потвърди',
        'added': 'Добавен',
        'additional-period-options': 'Допълнителни опции за период',
        'min': 'мин.',
        'not-extendable': 'Този пакет не може да бъде удължен!',
        'new-payment': 'Ново плащане',
        'weekend-package': 'Уикенд пакет',
        'tickets-limit-reached': 'Достигнат лимит на билети за този код!',
        'min-limit-bigger-than-tickets-count': 'Минималното количество билети за това събитие трябва да е по-голям от',
        'tickets-limit-per-purchase-reached': 'Достигнат лимит от билети за поръчка!',
        'partnership-with': 'В партньорство с',
        'payment-tax-amount': 'Такса за обработка на поръчката',
        'administration-tax': 'Такса за услуга',
        'director': 'Организатор',
        'duration': 'Продължителност',
        'No available tickets!': 'Не са намерени желания брой места в един сектор.'
    }
}