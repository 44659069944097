export default {
    translation: {
        'parking-varna': 'Parking Grand Mall Varna',
        'pay-parking': 'Pay parking',
        'car-number': 'Car number',
        'please-enter-car-number': 'Please enter car number!',
        'car-number-placeholder': 'Car number - АА0000АА',
        'recheck-after': 'Recheck for the price after',
        'parking-stay': 'Parking stay',
        'price-parking-stay': 'PRICE FOR PARKING STAY',
        'payment-tax': 'Payment operator tax',
        'bgn': 'BGN',
        'pay': 'Pay',
        'm': 'm',
        'd': 'd',
        'h': 'h',
        'to': 'to',
        'email-placeholder': 'E-Mail for receipt',
        'do-you-want-receipt': 'Enter E-mail to receive receipt',
        'successful-payment': 'Successful payment',
        'paid-until': 'Paid until',
        'time-to-leave': 'Time left to leave the parking',
        'parking-ticket-number': 'Parking ticket number',
        'expired-text': 'After the expiration of the paid time the price for parking stay for the next period is charged according to the price list.',
        'email-is-required': 'Please enter E-mail address to receive receipt!',
        'car-number-not-found': 'Car with this number is not in the parking!',
        'payment-failed': 'Payment failed!',
        // 'payment-failed-text': 'There was an error during the payment. Please try again!',
        'payment-failed-text': 'Please try again!',
        'try-again': 'Try again',
        'cyrillic-not-allowed': 'Car number should not contains cyrillic characters!',
        'updated-at': 'Updated at',
        'please-choose-zone': 'Please choose parking zone!',
        'hour': 'h.',
        'hours': 'hours',
        'please-choose-duration': 'Please choose parking duration!',
        'zone-time-left': 'Time left for parking',
        'extend-parking': 'Extend parking',
        'check-another-number': 'Check another number',
        'powered-by': 'powered by',
        'private-parking': 'Parking',
        'extend-parking-label': 'You can extend your parking time',
        'pay-for-another-number': 'Pay for another number',
        'choose-duration-label': 'Choose parking duration',
        'enter-car-number': 'Enter car number',
        'out-of-working-hours': 'The selected zone is out of working hours!',
        'active-parking-found': 'Active parking found for this number! Do you want to see more information about it?',
        'failed-payments': 'The system is currently being updated!',
        'failed-payments-2': 'Please use the payment terminals!',
        'check-clamp': 'Check clamp',
        'check': 'Check',
        'lifted-car': 'The car has been clamped.',
        'located-at': 'It\'s located at ',
        'fee': 'Fee',
        'clamp-paid': 'You have paid for the removal of the clamp for ',
        'clamp-paid-successfully': 'You have successfully paid for the removal of the clamp for ',
        'check-another-car': 'Check another car',
        'no-clamp': 'No clamp found for this car!',
        'parking-is-free': 'The selected zone is not active at the moment.',
        'choose-zone': 'Please select a zone!',
        'bg': 'Български',
        'en': 'English',
        'choose-ticket-type': 'Choose ticket type',
        'your-tickets': 'Your tickets',
        'total': 'Total',
        'payment-methods': 'Payment method',
        'card': 'Pay with card',
        'got-ages': 'Confirm that you are over 18 years old.',
        'tickets': 'Tickets',
        'enter-email-info': 'Enter your E-Mail to receive tickets',
        'enter-email': 'Enter E-Mail',
        'confirm-email': 'Confirm E-Mail',
        'buy': 'Buy',
        // 'general-terms-info': 'By clicking the "Pay as guest" or "Register" buttons, you agree to our ',
        'general-terms-info': 'By clicking the "Buy" button, you agree to our ',
        'general-terms': 'Terms and conditions',
        'owner-terms-info': 'I agree with',
        'owner-terms': 'the terms and conditions of the organizer',
        'owner-file-info': 'I agree with',
        'owner-file': 'the terms and conditions of the event',
        'privacy-policy-info': 'Learn how we collect, use and share your data in our ',
        'privacy-policy': 'Privacy policy',
        'provide-valid-email': 'Please provide a valid email address!',
        'email-is-required': 'Please enter email address!',
        'emails-must-match': 'Emails must match!',
        'got-ages-required': 'You must confirm that you are over 18 years old!',
        'general-terms-required': 'Please confirm that you are familiar with the Terms and conditions!',
        'owner-terms-required': 'Please confirm that you are familiar with the Organizer\'s terms!',
        'owner-file-required': 'Please confirm that you are familiar with the Event\'s terms!',
        'view-more': 'View more',
        // 'event-paid-successfully': 'You have successfully paid for the event! Your ticket will be sent to the specified E-Mail address!',
        'event-paid-successfully': 'You have successfully paid for your order! More details can be found at the specified E-Mail address!',
        'buy-another-event': 'Buy again',
        'standart': 'Standart',
        'child': 'Child',
        'student': 'Student',
        'retired': 'Disabled',
        'disabled': 'Retired',
        'organizer-info-tab': 'About the place',
        'important-info': 'Important',
        'organizer-info': 'Working hours',
        'description':'Description',
        'clamp':'Clamp',
        'car-has-clamp': 'Your car has been clamped.',
        'car-has-clamp-2': 'You can pay for the removal.',
        'adm-tax': 'Payment operator tax',
        'choose-date': 'Choose date',
        'choose': 'Choose',
        'choose-seats': 'Choose umbrellas', 
        'search-seat-name': 'Enter umbrella number', 
        'seat-number': 'Umbrella', 
        'row': 'Row', 
        'free': 'Free', 
        'occupied': 'Occupied', 
        'add': 'Add', 
        'add-anc': '+Items', 
        'event-ancillaries': 'Add items', 
        'number': 'Number',
        'loading': 'Loading',
        'submit-anc': 'Approve',
        'added': 'Added',
        'additional-period-options': 'Additional period options',
        'min': 'min.',
        'not-extendable': 'This package is not extendable!',
        'new-payment': 'New payment',
        'weekend-package': 'Weekend package',
        'tickets-limit-reached': 'Tickets limits reached!',
        'min-limit-bigger-than-tickets-count': 'The minimum ticket quantity for this event must be greater than',
        'tickets-limit-per-purchase-reached': 'Tickets limit per order reached!',
        'partnership-with': 'In collaboration with',
        'payment-tax-amount': 'Administration order fee',
        'administration-tax': 'Service fee',
        'director': 'Promoter',
        'duration': 'Duration',
        'No available tickets!': 'The desired number of seats in one sector is not available.'
    }
}